const en_US = {
  products: 'Products',
  rankings: 'Rankings',
  log_out: 'Log Out',
  log_in: 'Log In',
  more: 'More',
  More: 'More',
  top_products: 'Top Products',
  top_brands: 'Top Brands',
  top_ingredients: 'Top Ingredients',
  top_benefits: 'Top Benefits',
  top_scene: 'Top Scene',
  Recent_1_Month: 'Recent 1 Month',
  Recent_3_Months: 'Recent 3 Months',
  Recent_6_Months: 'Recent 6 Months',
  Sales: 'Sales',
  Commodity_sales: 'Commodity sales',
  Discussion_Volume: 'Discussion Volume',
  Discussion_diff: 'Discussion',
  Makeup: 'Makeup',
  Skincare: 'Skincare',
  Personal_care: 'Personal care',
  Rank: 'Rank',
  ProductName: 'Product Name',
  BrandName: 'BrandName',
  Ingredient: 'Ingredient',
  Benefit: 'Benefit',
  Ring_growthRate: 'MOM',
  Year_growthRate: 'YOY',
  Trend: 'Trend',
  Top_Discussed_Brands: 'Top Discussed Brands:',
  Top_Sales_Products: 'Best-Selling Products:',
  Top_Discussed_Products: 'Top Discussed Products:',
  Top_Discussed_Ingredients: 'Top Discussed Ingredients:',
  Top_Discussed_Makeup_Efficacies: 'Top Discussed Makeup Efficacies:',
  Top_Discussed_Skincare_Benefits: 'Top Discussed Skincare Benefits:',
  Product_Category: 'Product Category',
  Ingredient_Category: 'Ingredient Category',
  Benefit_Category: 'Benefit Category',
  Search: 'Search',
  Forecast_Data_Download: 'ForecastData Download',
  DataDescription: 'Data Description',
  DataExport: 'Data Export',
  xx_products: 'products',
  xx_data: 'data',
  Brand: 'Brand',
  Category: 'Category',
  // Efficacy: 'Efficacy',
  Efficacy: 'Benefit',
  Benefit_1: 'Benefit',
  Benefit_2: 'Benefit :',
  Skin_Types: 'Skin Types',
  Price: 'Price',
  PricePerception: 'Price Perception',
  Clear: 'Clear',
  Sorted_by: 'Sorted by',
  Keyword: 'Keyword',
  xx_Products_Found: '{total} Products Found',
  Home_Page: 'Home Page',
  Query_List: 'Query List',
  Series: 'Series',
  E_commerce_Price: 'E-commerce Price',
  Product_Claim: 'Product Claim',
  Expand: 'Expand',
  Collapse: 'Collapse',
  Fold: 'Fold',
  Trends: 'Discussion Trend', // Trends
  E_Commerce_Reviews: 'E-Commerce Reviews',
  Consumer_Sentiment: 'Consumer Sentiment',
  Negative: 'Negative',
  Neutral: 'Neutral',
  Positive: 'Positive',
  Customer_Feedback: 'Customer Feedback',
  Word_Cloud: 'Word Cloud',
  Weibo: 'Weibo',
  RED: 'RED',
  Tmall: 'Tmall',
  JD: 'JD',
  TikTok: 'TikTok',
  Product_Comparison: 'Comparison',
  Brand_Comparison: 'Brand Comparison',
  Category_Comparison: 'Category Comparison',
  Benefit_Comparison: 'Benefit Comparison',
  Add_to_Compare: 'Add to Compare',
  Time_frame: 'Time frame',
  Product_Details: 'Product Details',
  Consumer_Discussion: 'Consumer Discussion',
  Brand_name: 'Brand Name',
  Social_Discussion: 'Social Discussion',
  E_commerce_Reviews: 'E-commerce Discussion',
  Overall_Discussion: 'Overall Discussion',
  Discussion_Trend_Comparison: 'Discussion Trend Comparison',
  Sentiment_Comparison: 'Sentiment Comparison',
  Word_Cloud_Comparison: 'Word Cloud Comparison',
  Brands: 'Brands',
  Reports: 'Reports',
  KOL_KOC_Discovery: 'Influence KOL/KOC',
  Country: 'Country',
  Chinese_Name: 'Chinese Name',
  English_Name: 'English Name',
  Number_of_Products: 'Product Quantity',
  Products: 'Products',
  Brand_Analytics: 'Brand Analytics',
  Data_Tips: 'Data collection from recent 1 year',
  Related_Brands: 'Related Brands',
  All: 'All',
  Discussion_Trend: 'Discussion Trend',
  Sales_Trend: 'Sales Trend',
  Discussion_by_Platform: 'Comments',
  Proportion_of_channel_heat: 'Proportion of Channel Discussion',
  Sentiment: 'Consumer Sentiment',
  Top_Discussed_Benefits: 'Top5 Benefits',
  Top_Discussed_Ingredients_2: 'Top5 Ingredients',
  Weibo_Hot_Topics: 'Weibo Hot Topics',
  Hot_Topics: 'Hot Topics',
  Add_Brand: 'Add Brand',
  Discussion_Rank: 'Discussion Rank',
  Sales_Rank: 'Top Products of Sales',
  Discussed_Products: 'Discussed Products',
  Sales_Products: 'Sales Products',
  Discussed_Ingredients_2: 'Discussed Ingredients',
  Discussed_Benefits: 'Benefits Discussed',
  Change_Password: 'Change PWD',
  Data_Tips_1: '* Data collection from recent 1 month',
  Data_Tips_2: '* Data collection from recent 3 months',
  Data_Tips_3: '* Suggest using Google Chrome, Firefox browser',
  Data_Tips_4: '* Data collection from recent 3 months',
  Buzz_Comparison: 'Discussion Comparison',
  Sales_Comparison: 'Sales Comparison',
  Top10_Brand_Buzz: 'Top 10 Brand Discussion',
  Top10_Brand_Sales: 'Top 10 Brand Sales',
  Top10_Function_Benefit: 'Top 10 Function & Benefit',
  Top10_Ingredients: 'Top 10 Ingredients',
  Top10_Products_Buzz: 'Top 10 Products Discussion',
  Top10_Products_Sales: 'Top 10 Products Sales',
  Add_Brand_Tips: 'You have already added {total}/5 Brands',
  // Category_Analysis: 'Category Analysis',
  Category_Analysis: 'Category',
  category_composition: 'Category Composition',
  category_quantity_composition: 'category Quantity Composition',
  category_Hot_composition: 'Category Discussion Composition',
  Issues: 'Issues',
  Notification: 'Notification',
  Unread: 'Unread',
  Read: 'Read',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Problem_Function: 'Problem Function',
  Problem_Topic: 'Problem Topic',
  Problem_Description: 'Problem Description',
  Any_suggestion: 'Any suggestion or feedback',
  Upload_Pictures: 'Upload Pictures',
  Contact: 'Contact',
  Issue_Report: 'Issue Report',
  Forgot_Password: 'Forgot Password',
  Average: 'Average',
  Published_Date: 'Release Date',
  Downloads: 'Downloads',
  Download_Report: 'Download',
  Products_Trending_Quadrant: 'Products Discussion Quadrants',
  Trending_Quadrant: 'Attribute Discussion Quadrants',
  Products_Sales_Quadrant: 'Products Sales Quadrant',
  Show_Names: 'Show Names',
  Trending_Index: 'Trending Index',
  Growth_Index: 'GrowthIndex',
  Low_Discussion_but_Trendy: 'Low Discussion but Trendy',
  Low_Discussion_and_Stable: 'Low Discussion and Stable',
  High_Discussion_and_Trendy: 'High Discussion and Trendy',
  High_Discussion_and_Stable: 'High Discussion and Stable',
  Low_Sales_but_Trendy: ' Low Sales but Trendy',
  Low_Sales_and_Stable: 'Low Sales and Stable',
  High_Sales_and_Trendy: 'High Sales and Trendy',
  High_Sales_and_Stable: 'High Sales and Stable',

  Opportunity_trend: 'Opportunity Trend',
  Future_trends: 'Future Trends',
  Hot_trend: 'Hot Trend',
  Equilibrium_trend: 'Equilibrium Trend',

  Competitive_opportunities: 'Competitive Opportunities',
  Competitive_Blue_Sea: 'Competitive Blue Sea',
  Competitive_Red_Sea: 'Competitive Red Sea',
  Competition_saturation: 'Competition Saturation',

  Hot_Tiktok_Videos: 'Hot Tiktok Videos',
  Word_Cloud_Configuration: 'Word Cloud Configuration',
  Generate: 'Generate',
  Like: 'Like',
  Comments: 'Comments',
  Share: 'Share',
  Analysis: 'Analysis',
  Use_Experience: 'Use Experience',
  Packaging: 'Packaging',
  Fragrance: 'scent',
  Fragrance2: 'Fragrance',
  Attribute_Sentiment: 'Attribute Sentiment',
  Attribute_Discussion: 'Attribute Discussion',
  Discussion_Proportion: 'Discussion Proportion',
  Sentiment_Proportion: 'Sentiment Proportion',
  Sentiment_Volume: 'Sentiment_Volume',
  Data_Analysis: 'Data Analysis',
  Level: 'Level',
  Masses: 'mass',
  HighEnd: 'prestige',
  Luxury: 'luxury',
  // Competitive_Analysis: 'Competitive Analysis',
  Competitive_Analysis: 'Competitive',
  Product: 'Product',
  Reset: 'Reset',
  Time: 'Time',
  E_Commerce: 'E Commerce',
  Social: 'Social',
  SalesTrend: 'Sales Trend',
  DiscussionTrend: 'Discussion Trend',
  Attribute_Buzz_Comparison: 'Attribute Discussion Comparison',
  Attribute_Buzz_Trend_Comparison: 'Attribute Discussion Trend Comparison',
  Attribute_Sentiment_Comparison: 'Attribute Sentiment Comparison',
  Attribute_Sentiment_Trend_Comparison: 'Attribute Sentiment Trend Comparison',
  SentimentComparison: 'Sentiment Comparison',
  Satisfaction_Trend_Comparison: 'Satisfaction Trend Comparison',
  Attribute_Feedback_Comparison: 'Attribute Feedback Comparison',
  SkinCare_Industry: 'SkinCare Industry',
  MakeUp_Industry: 'MakeUp Industry',
  Personal_Protection_Industry: 'Personal Protection Industry',
  Hair_Industry: 'Hair Industry',
  Prediction_Analysis: 'Trend Analysis',
  Prediction_Analysis1: 'Trend Analysis',
  Proportion_Analysis: 'Proportion Analysis',
  All_Industry: 'All Industry',
  Service: 'Service',
  Promotion: 'Promotion',
  Analysis_Direction: 'Analysis Direction',
  Time_Range: 'Time Range',
  Download_All_Images: 'Download All Images',
  Download_All_Data: 'Download All Data',
  Buzz: 'Buzz',
  Buzz_Z_Score: 'Buzz (Z-Score)',
  Select_Attributes: 'Select_Attributes',
  Positives: 'Positive',
  Negatives: 'Negative',
  Start_to_Compare: 'Start_to_Compare',
  Account_Management: 'Account Management',
  Switch_Language: 'Switch Language',
  Averages: 'Average',
  Volume: 'Volume',
  Volume_Z_Score: 'Volume_Z_Score',
  Selected_Attributes: 'Selected Attributes',
  // CrowdAnalysis: 'Crowd Analysis',
  CrowdAnalysis: 'Audience',
  Analysis_Object: 'Analysis Object',
  Analysis_Channel: 'Analysis Channel',
  Crowd_Setting: 'Crowd Setting',
  Age: 'Age',
  Gender: 'Gender',
  City: 'City(All)',
  Male: 'Male',
  Female: 'Female',
  Under18: 'Under 18',
  Upwards55: 'Upwards 55',
  GenderRatio: 'Gender Ratio',
  AgeDistribution: 'Age Distribution',
  Top10_Follow_Brand: 'Top10 Follow Brand',
  Top10_Follow_Category: 'Top10 Follow Category',
  Top10_Follow_Commodity: 'Top10 Follow Commodity',
  WeiBo_Authentication: 'WeiBo Authentication',
  GeographicalDistribution: 'Geographical Distribution',
  AttributeWordCloud: 'AttributeWordCloud',
  CommentExhibition: 'CommentExhibition',
  Attribute_Sentiment_Trend: 'Attribute Sentiment Trend',
  Top10_Efficacy_Of_Concern: 'Top20 Efficacy Of Concern',
  Top10_Ingredients_Of_Concern: 'Top10 Ingredients Of Concern',
  Scene: 'Scene',
  Category_Discussion_Proportion: 'Category Discussion Proportion',
  Brand_Discussion_Proportion: 'Brand Discussion Proportion',
  Overall_Sentiment_Value: 'Actual Overall Liking',
  Overall_emotion: 'Overall Emotion',
  Sentiment_Trend_Prediction: 'Sentiment Trend Prediction',
  Forecast_Product: 'Product',
  Forecast_Product_QDA: 'QDA Forecast Product',
  QDA_Virtual_Goods: 'Virtual Product',
  Forecast_Record: 'Forecast Record',
  Positive_Sentiment_Score_Statistics: 'Actual Overall Liking',
  Positive_Sentiment_Score: 'Positive Sentiment Score',
  Positive_Sentiment_Score_Forecast: 'Predicted Overall Liking',
  Platform_Emotion_Score: 'Actual Overall Liking',
  Positive_Sentiment_Score_ForecastQDA: 'Predicted Overall Liking',
  QDA_Emotion_Prediction: 'QDA Predictive Analysis',
  Virtual_commodity_reference: 'Virtual Product Reference',
  Virtual_commodity_name: 'Virtual Commodity Name',
  Reference_QDAtrade_name: 'Reference QDAtrade',
  Score: 'Score',
  Attribute_Prediction_Coefficient: 'Attribute Prediction Coefficient',
  Prediction_Analysis: 'Run Prediction',
  Restore_Default: 'Restore Default',
  Attribute_Dimension: 'Attribute Segment',
  TOP: 'TOP',
  AttributeName: 'AttributeName',
  Scoring_Benchmark: 'Score Range',
  Original_score: 'Original Score',
  Prediction_score: 'Modified Score',
  Product_dirve_value: 'Product Driver Analysis',
  Coefficient: 'Coefficient',
  Operation: 'Operation',
  Load: 'Load',
  Category_Market: 'Category Market',
  Discussion_Volume_Analysis: 'Discussion Volume Analysis',
  Sales_Analysis: 'Sales Analysis',
  Attribute_Analysis: 'Attribute Analysis',
  Market_heat_Pattern: 'Market Discussion Pattern',
  Market_sales_pattern: 'Market Sales Pattern',
  Category_WordCloud: 'Category WordCloud',
  Attribute_Keyword: 'Attribute Keyword',
  Interest_WordCloud: 'Interest WordCloud',
  Skin_WordCloud: 'Skin Problems',
  List_Time: 'Launch Time',
  New_Quantity: 'Number of New Launch',
  New_Goods: 'New Product List',
  Category_New_Trends: 'New Product Launch Trend by Categories',
  New_Product_Type: 'New Product Type',
  New_Ingredients: 'New Ingredients',
  NewListing: 'Brand-New Launch',
  Upgrade_Iteration: 'Product Upgrade',
  Restricted_Payment: 'Limited Edition',
  Joint_Payment: 'Jointly Branded',
  New_Series: 'New Series',
  Official_Publicity_Effect: 'Official Publicity Effect',
  Official_Publicity_Ingredients: 'Official_Publicity Ingredients',
  Last_New_Date: 'Launch Date',
  xx_New_Products: '{New_Quantity_Pagination_Total}New Products',
  New_Products_Count: '{countNum}New Products',
  New_Product: 'New Product',
  YES: 'YES',
  NO: 'NO',
  NoData: 'No Data',
  New_Product_Modal_Component:
    'with"{New_Product_Modal_Component}"new products',
  Positive_Sentiment_Mean: 'Average Value',
  Save_Images: 'SaveImages',
  Download_Data: 'DownloadData',
  Channel_heat: 'Channel Discussion',
  online_retailers: 'Online Retailers',
  social_media: 'Social Media',
  scene_Heat_WordCloud: 'Scene Discussion WordCloud',
  Brand_Discussion: 'Brand Discussion',
  UGC_Discussion: 'UGC Discussion',
  BGC_Discussion: 'BGC Discussion',
  Commodity_Keywords: 'Product',
  Commodity_Brand: 'Brand',
  New_Express: 'New Products',
  English_Name: 'English Name',
  Brand_Word_Volume: 'Brand Word Volume',
  Include: 'Include',
  Dont_Include: 'Dont Include',
  Heat_RingTrend: 'Discussion Trend & MoM Growth',
  Heat_RingTrend_2: 'Discussion Trend & YoY Growth',
  Sales_RingTrend: 'Sales Trend & MOM Growth',
  Sales_RingTrend_2: 'Sales Trend & YOY Growth',
  ChannelType: 'Channel',
  Category_Shapley: 'Category Driver',
  Goods_Shapley: 'Product Driver',
  Platform_Analysis_Pattern: 'E-comm Predictive Analysis',
  QDA_Analysis_Pattern: 'QDA Predictive Analysis',
  QDA_Emotional_value: 'Predicted Overall Liking',
  platform_Emotional_value: 'platformEmotionalvalue',
  QDA_Data_Upload: 'QDA Data Upload',
  Launched: 'Launched or Not',
  Virtual_Commodity_Analysis: 'Virtual Product Design',
  Product_PCA_diagram: 'Product PCA diagram',
  Product_Sensory_Attribute_Guidance: 'Product Sensory Attribute Guidance',
  brand_WordCloud: 'Brand WordCloud',
  brand_Original_WordCloud: 'Brand Original WordCloud',
  brand_attribute_WordCloud: 'Brand Attribute WordCloud',
  Original_show: 'Original',
  View_original: 'View Original',
}

export default en_US
