const zh_CN = {
  products: '商品库',
  rankings: '排行榜',
  log_out: '退出登录',
  log_in: '登录',
  more: '更多',
  More: '更多',
  top_products: '商品排行榜',
  top_brands: '品牌排行榜',
  top_ingredients: '成分排行榜',
  top_benefits: '功效排行榜',
  top_scene: '场景排行榜',
  Recent_1_Month: '近一个月',
  Recent_3_Months: '近三个月',
  Recent_6_Months: '近六个月',
  Sales: '销量',
  Commodity_sales: '商品销量',
  Discussion_Volume: '热度',
  Discussion_diff: '热度',
  Makeup: '彩妆',
  Skincare: '护肤',
  Personal_care: '个护',
  Rank: '排名',
  Brand_name: '品牌名称',
  ProductName: '商品名称',
  BrandName: '品牌名称',
  Ring_growthRate: '环比涨幅',
  Year_growthRate: '同比涨幅',
  Trend: '趋势',
  Top_Discussed_Brands: '品牌热度榜:',
  Top_Sales_Products: '商品销量榜:',
  Top_Discussed_Products: '商品热度榜:',
  Top_Discussed_Ingredients: '成分热度榜:',
  Top_Discussed_Makeup_Efficacies: '彩妆功效热度榜:',
  Top_Discussed_Skincare_Benefits: '护肤功效热度榜:',
  Product_Category: '商品分类',
  Ingredient_Category: '成分分类',
  Benefit_Category: '功效分类',
  Search: '查找',
  Forecast_Data_Download: '预测数据下载',
  DataDescription: '数据说明',
  DataExport: '数据导出',
  xx_products: '款产品',
  xx_data: '条数据',
  Brand: '品牌',
  Category: '品类',
  Ingredient: '成分',
  Benefit: '功效',
  // Benefits: '功效',
  Efficacy: '功效',
  Benefit_1: '功效',
  Benefit_2: '官宣功效 :',
  Skin_Types: '肤质',
  Price: '价格',
  PricePerception: '价格认知',
  Clear: '清除',
  Sorted_by: '已筛选条件',
  Keyword: '关键词',
  xx_Products_Found: '找到 {total} 商品',
  Home_Page: '首页',
  Query_List: '搜索列表',
  Series: '系列',
  E_commerce_Price: '参考价',
  Product_Claim: '简介',
  Expand: '展开',
  Collapse: '收起',
  Trends: '热度走势',
  E_Commerce_Reviews: '电商评论',
  Social_Discussion: '社媒讨论',
  Consumer_Sentiment: '用户观点',
  Negative: '负面',
  Neutral: '中性',
  Positive: '正面',
  Customer_Feedback: '大家的口碑',
  Word_Cloud: '相关词云',
  Weibo: '微博',
  RED: '小红书',
  Tmall: '天猫',
  JD: '京东',
  TikTok: '抖音',
  Product_Comparison: '商品对比',
  Brand_Comparison: '品牌对比',
  Category_Comparison: '分类对比',
  Benefit_Comparison: '功效对比',
  Add_to_Compare: '加入对比',
  Time_frame: '统计时间',
  Product_Details: '商品信息',
  Consumer_Discussion: '热度概况',
  E_commerce_Reviews: '电商热度',
  Overall_Discussion: '整体热度',
  Discussion_Trend_Comparison: '热度走势对比',
  Sentiment_Comparison: '情感分析对比',
  Word_Cloud_Comparison: '词云对比',
  Brands: '品牌库',
  Reports: '菱歌研究院',
  KOL_KOC_Discovery: '影响力KOL/KOC',
  Country: '国家',
  Chinese_Name: '中文名',
  English_Name: '英文名',
  Number_of_Products: '商品数量',
  Products: '品牌产品',
  Brand_Analytics: '品牌分析',
  Data_Tips: '以下为近一年的统计结果',
  Related_Brands: '相关品牌',
  All: '全部',
  Discussion_Trend: '热度趋势',
  Sales_Trend: '销量趋势',
  Discussion_by_Platform: '评论占比',
  Proportion_of_channel_heat: '渠道热度占比',
  Channel_heat: '渠道热度',
  Sentiment: '情感指数',
  Top_Discussed_Benefits: '功效提及Top5',
  Top_Discussed_Ingredients_2: '成分提及Top5',
  Weibo_Hot_Topics: '微博热门话题',
  Hot_Topics: '热门话题',
  Add_Brand: '添加品牌',
  Discussion_Rank: '热度排名',
  Sales_Rank: '销售排名',
  Discussed_Products: '商品热度',
  Sales_Products: '商品销量',
  Discussed_Ingredients_2: '成分提及',
  Discussed_Benefits: '功效提及',
  Change_Password: '修改密码',
  Data_Tips_1: '* 近一个月的统计数据',
  Data_Tips_2: '* 近三个月的统计数据',
  Data_Tips_3: '* 建议您使用谷歌、火狐浏览器',
  Data_Tips_4: '* 以下为近三个月的统计数据',
  Buzz_Comparison: '热度对比',
  Sales_Comparison: '销量对比',
  Top10_Brand_Buzz: '品牌热度占比top10',
  Top10_Brand_Sales: '品牌销量占比top10',
  Top10_Function_Benefit: '热门功效top10',
  Top10_Ingredients: '热门成分top10',
  Top10_Products_Buzz: '商品热度top10',
  Top10_Products_Sales: '商品销量top10',
  Add_Brand_Tips: '您已经添加{total}/5个品牌',
  Category_Analysis: '品类分析',
  category_composition: '品类构成占比',
  category_quantity_composition: '品类数量构成占比',
  category_Hot_composition: '品类热度构成占比',
  Issues: '问题反馈',
  Notification: '平台消息',
  Unread: '未读',
  Read: '已读',
  Confirm: '确认',
  Cancel: '取消',
  Problem_Function: '问题功能',
  Problem_Topic: '问题类型',
  Problem_Description: '问题描述',
  Any_suggestion: '简单描述意见建议',
  Upload_Pictures: '上传图片',
  Contact: '联系方式',
  Issue_Report: '问题反馈',
  Forgot_Password: '忘记密码',
  Average: '均值',
  Published_Date: '发布日期',
  Downloads: '下载量',
  Download_Report: '下载报告',
  Products_Trending_Quadrant: '商品热度四象限',
  Trending_Quadrant: '属性热度四象限',
  Products_Sales_Quadrant: '商品销量四象限',
  Show_Names: '显示名称',
  Trending_Index: '趋势指数',
  Growth_Index: '涨幅指数',
  Low_Discussion_but_Trendy: '低热度&高趋势',
  Low_Discussion_and_Stable: '低热度&低趋势',
  High_Discussion_and_Trendy: '高热度&高趋势',
  High_Discussion_and_Stable: '高热度&低趋势',
  Low_Sales_but_Trendy: '低销量&高趋势',
  Low_Sales_and_Stable: '低销量&低趋势',
  High_Sales_and_Trendy: '高销量&高趋势',
  High_Sales_and_Stable: '高销量&低趋势',

  Opportunity_trend: '机会趋势',
  Future_trends: '未来趋势',
  Hot_trend: '火热趋势',
  Equilibrium_trend: '均衡趋势',

  Competitive_opportunities: '竞争机会',
  Competitive_Blue_Sea: '竞争蓝海',
  Competitive_Red_Sea: '竞争红海',
  Competition_saturation: '竞争饱和',

  Hot_Tiktok_Videos: '热门抖音视频',
  Word_Cloud_Configuration: '词云配置',
  Generate: '生成',
  Like: '点赞',
  Comments: '评论',
  Share: '分享',
  Analysis: '分析',
  Use_Experience: '使用感受',
  Packaging: '包装',
  Fragrance: '香味',
  Fragrance2: '气味',
  Attribute_Sentiment: '属性情感',
  Attribute_Discussion: '属性热度',
  Discussion_Proportion: '热度占比',
  Sentiment_Proportion: '情感占比',
  Sentiment_Volume: '情感声量',
  Data_Analysis: '数据分析',
  Level: '等级',
  Masses: '大众',
  HighEnd: '高端',
  Luxury: '奢华',
  Competitive_Analysis: '竞品分析',
  Product: '商品',
  Reset: '重置',
  Time: '时间',
  E_Commerce: '电商',
  Social: '社媒',
  SalesTrend: '销量走势',
  DiscussionTrend: '热度走势',
  Attribute_Buzz_Comparison: '属性热度对比',
  Attribute_Buzz_Trend_Comparison: '属性热度走势对比',
  Attribute_Sentiment_Comparison: '属性情感对比',
  Attribute_Sentiment_Trend_Comparison: '属性情感走势对比',
  SentimentComparison: '情感对比',
  Satisfaction_Trend_Comparison: '满意度走势对比',
  Attribute_Feedback_Comparison: '属性口碑对比',
  SkinCare_Industry: '护肤行业',
  MakeUp_Industry: '彩妆行业',
  Personal_Protection_Industry: '个护行业',
  Hair_Industry: '头发行业',
  Prediction_Analysis: '趋势分析',
  Prediction_Analysis1: '趋势分析',
  Proportion_Analysis: '占比分析',
  All_Industry: '全部行业',
  Service: '服务',
  Promotion: '促销',
  Analysis_Direction: '分析方向',
  Time_Range: '分析时间',
  Download_All_Images: '下载全部图片',
  Save_Images: '保存图片',
  Download_All_Data: '下载全部数据',
  Download_Data: '下载数据',
  Buzz: '提及量',
  Buzz_Z_Score: '提及量Z值',
  Select_Attributes: '分析指标',
  Positives: '正向',
  Negatives: '负向',
  Start_to_Compare: '开始对比',
  Account_Management: '账户管理',
  Switch_Language: '切换语言',
  Averages: '平均数',
  Volume: '声量',
  Volume_Z_Score: '声量Z值',
  Selected_Attributes: '已选属性',
  CrowdAnalysis: '人群分析',
  Analysis_Object: '分析对象',
  Analysis_Channel: '分析渠道',
  Crowd_Setting: '人群设定',
  Age: '年龄',
  Gender: '性别',
  City: '城市(全部)',
  Male: '男',
  Female: '女',
  Under18: '18以下',
  Upwards55: '55以上',
  GenderRatio: '性别比例',
  AgeDistribution: '年龄分布',
  Top10_Follow_Brand: '关注的品牌占比Top10',
  Top10_Follow_Category: '关注的品类占比Top10',
  Top10_Follow_Commodity: '关注的商品占比Top10',
  WeiBo_Authentication: '微博认证',
  GeographicalDistribution: '地域分布',
  AttributeWordCloud: '属性词云',
  CommentExhibition: '评论展示',
  Attribute_Sentiment_Trend: '属性情感趋势',
  Top10_Efficacy_Of_Concern: '关注的功效top20',
  Top10_Ingredients_Of_Concern: '关注的成分top10',
  Scene: '场景',
  Category_Discussion_Proportion: '品类热度占比',
  Brand_Discussion_Proportion: '品牌热度占比',
  Overall_Sentiment_Value: '整体情感值',
  Overall_emotion: '整体情感',
  Sentiment_Trend_Prediction: '情感趋势预测',
  Forecast_Product: '预测商品',
  Forecast_Product_QDA: 'QDA预测商品',
  QDA_Virtual_Goods: 'QDA虚拟商品',
  Forecast_Record: '预测记录',
  Positive_Sentiment_Score_Statistics: '正情感得分(统计)',
  Positive_Sentiment_Score: '正情感得分(统计)',
  Positive_Sentiment_Score_Forecast: '正情感得分(预测)',
  Platform_Emotion_Score: '平台情感得分',
  Positive_Sentiment_Score_ForecastQDA: 'QDA情感预测得分',
  QDA_Emotion_Prediction: 'QDA情感预测',
  Virtual_commodity_reference: '虚拟商品参考',
  Virtual_commodity_name: '虚拟商品名称',
  Reference_QDAtrade_name: '参考QDA商品名称',
  Score: '分数',
  Attribute_Prediction_Coefficient: '属性预测系数',
  Prediction_Analysis: '预测分析',
  Restore_Default: '恢复默认',
  Attribute_Dimension: '属性维度',
  TOP: 'TOP',
  AttributeName: '属性名称',
  Scoring_Benchmark: '评分基准',
  Original_score: '原始得分',
  Prediction_score: '预测得分',
  Product_dirve_value: '产品dirve值',
  Coefficient: '系数',
  Operation: '操作',
  Load: '载入',
  Category_Market: '品类大盘',
  Discussion_Volume_Analysis: '热度分析',
  Sales_Analysis: '销量分析',
  Attribute_Analysis: '属性分析',
  Market_heat_Pattern: '市场热度格局',
  Market_sales_pattern: '市场销量格局',
  Category_WordCloud: '品类词云',
  Attribute_Keyword: '属性关键词',
  Interest_WordCloud: '兴趣词云',
  Skin_WordCloud: '肌肤问题',
  List_Time: '上市时间',
  New_Quantity: '上新数量',
  New_Goods: '上新商品',
  Category_New_Trends: '品类上新趋势',
  New_Product_Type: '新品类型',
  New_Ingredients: '新品成分',
  NewListing: '全新上市',
  Upgrade_Iteration: '升级迭代',
  Restricted_Payment: '限定款',
  Joint_Payment: '联名款',
  New_Series: '新系列',
  Official_Publicity_Effect: '官宣功效',
  Official_Publicity_Ingredients: '官宣成分',
  Last_New_Date: '上新日期',
  xx_New_Products: '共{New_Quantity_Pagination_Total}件新品',
  New_Products_Count: '共{countNum}件新品',
  New_Product: '新品',
  YES: '是',
  NO: '否',
  NoData: '暂无数据',
  New_Product_Modal_Component: '含"{New_Product_Modal_Component}"成分的新品',
  Positive_Sentiment_Mean: '正情感均值',
  online_retailers: '电商',
  social_media: '社媒',
  scene_Heat_WordCloud: '场景热度词云',
  Brand_Discussion: '品牌热度值',
  UGC_Discussion: 'UGC热度值',
  BGC_Discussion: 'BGC热度值',
  Commodity_Keywords: '商品关键词',
  Commodity_Brand: '商品品牌',
  New_Express: '新品速递',
  English_Name: '英文名称',
  Brand_Word_Volume: '品牌词声量',
  Include: '包含',
  Dont_Include: '不包含',
  Heat_RingTrend: '热度&环比趋势',
  Heat_RingTrend_2: '热度&同比趋势',
  Sales_RingTrend: '销量&环比趋势',
  Sales_RingTrend_2: '销量&同比趋势',
  ChannelType: '渠道',
  Category_Shapley: '品类shapley',
  Goods_Shapley: '商品shapley',
  Platform_Analysis_Pattern: '平台分析模式',
  QDA_Analysis_Pattern: 'QDA分析模式',
  QDA_Emotional_value: 'QDA情感值',
  platform_Emotional_value: '平台情感值',
  QDA_Data_Upload: 'QDA数据上传',
  Launched: '是否上市',
  Virtual_Commodity_Analysis: '虚拟产品设计',
  Product_PCA_diagram: '产品PCA示意图',
  Product_Sensory_Attribute_Guidance: '产品感官属性导览图',
  brand_WordCloud: '品牌词云',
  brand_Original_WordCloud: '品牌原生词云',
  brand_attribute_WordCloud: '品牌属性词云',
  Original_show: '原帖展示',
  View_original: '查看原帖',
}

export default zh_CN
