import React, { Component, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import storage from 'sweet-storage'
import { ConfigProvider } from 'antd'
import zh_cn from 'antd/es/locale/zh_CN'
import eng from 'antd/es/locale/en_US'

import PrivateRoute from './app/components/privateRoute/privateRoute'

import moment from 'moment'
import 'moment/locale/zh-cn'

import { IntlProvider } from 'react-intl' /* react-intl imports */
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
import http from './utils/http'
import ReactGA from 'react-ga4'
import GoogleAnalytics from './utils/GoogleAnalytics'
const Main = lazy(() => import('./app/main/main'))
const Login = lazy(() => import('./app/login/login'))
const ForgotPassword = lazy(() => import('./app/forgotPassword/forgotPassword'))
const GetBaidu = (props) => {
  let children = props.children
  let _hmt = _hmt || []
  ;(function () {
    var hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?2fc4134284a557ef17729d2d4e6daf43'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
  return children
}

class App extends Component {
  static childContextTypes = {
    dataTouchAuthed: PropTypes.bool,
    login: PropTypes.func,
    locale: PropTypes.string,
    languageChange: PropTypes.func,
    userId: PropTypes.string,
    setUserId: PropTypes.func,
    unreadMessagesTotal: PropTypes.number,
    setUnreadMessagesTotal: PropTypes.func,
  }

  state = {
    dataTouchAuthed: false,
    locale: '',
    userId: '',
    unreadMessagesTotal: 0,
  }

  componentDidMount() {
    if (storage.get('dataTouchAuthed')) {
      this.setState({
        dataTouchAuthed: true,
      })
    }

    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      lang = (navigator.language || navigator.userLanguage).substr(0, 2) //常规浏览器语言和IE浏览器
      localStorage.setItem('locale', lang)
    }

    if (lang === 'zh') {
      this.setState({
        locale: 'zh',
      })
      moment.locale('zh-cn')
    } else {
      this.setState({
        locale: 'en',
      })
      moment.locale('en')
    }
  }

  login(account) {
    const detailId = sessionStorage.getItem('detailId')
    http
      .post('/user/login', {
        username: account.email,
        password: account.password,
      })
      .then((res) => {
        if (res.code === 200) {
          storage.save('dataTouchAuthed', true)
          storage.save('account', account.email)
          storage.save('token', res.data.token)
          ReactGA.gtag('set', 'user_id', account.email)

          ReactGA.gtag('set', 'user_properties', {
            user_id: account.email,
            user_email: account.email,
            user_Id: account.email,
          })
          this.setState(
            {
              dataTouchAuthed: true,
            },
            () => {
              if (detailId !== null && detailId !== 'notHaveId') {
                this.props.history.replace(`/detail/${detailId}`)
              } else {
                this.props.history.replace(`/`)
              }
            },
          )
        }
      })
  }

  languageChange = (lang) => {
    this.setState({
      locale: lang,
    })
    localStorage.setItem('locale', lang)
  }

  setUserId = (id) => {
    this.setState({
      userId: id,
    })
  }

  setUnreadMessagesTotal = (total) => {
    this.setState({
      unreadMessagesTotal: total,
    })
  }

  getChildContext() {
    return {
      dataTouchAuthed: this.state.dataTouchAuthed,
      login: (account) => this.login(account),
      locale: this.state.locale,
      languageChange: (lang) => this.languageChange(lang),
      userId: this.state.userId,
      setUserId: (id) => this.setUserId(id),
      unreadMessagesTotal: this.state.unreadMessagesTotal,
      setUnreadMessagesTotal: (total) => this.setUnreadMessagesTotal(total),
    }
  }

  render() {
    const { locale } = this.state
    let messages = {}
    messages['en'] = en_US
    messages['zh'] = zh_CN

    return (
      <IntlProvider
        locale={locale === 'zh' ? zh_CN : en_US}
        messages={messages[locale]}
      >
        <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
          <Suspense fallback={null}>
            <GoogleAnalytics>
              <GetBaidu>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <PrivateRoute
                    path="/"
                    authed={this.state.dataTouchAuthed}
                    component={Main}
                  />
                </Switch>
              </GetBaidu>
            </GoogleAnalytics>
          </Suspense>
        </ConfigProvider>
      </IntlProvider>
    )
  }
}

export default withRouter(App)
