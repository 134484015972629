import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const containsNumber = (str) => {
  const b = /\d/
  return b.test(str)
}

const interceptUrl = (url) => {
  const index = [
    'home',
    'detail',
    'ranking',
    'contrast',
    'ingredientEffectList',
    'rank-analysis',
    'competitive-analysis',
    'competitive-selection',
    'category-analysis',
    'crowdAnalysis',
    'forecast-model',
    'forecastQDA',
    'newProductStatistics',
    'brand-goods',
  ].findIndex((item) => {
    return url.indexOf(item) > 0
  })
  return index
}

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : interceptUrl(props.location.pathname) >= 0 &&
          containsNumber(props.location.pathname) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
