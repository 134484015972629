import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
require('dotenv').config()
ReactDOM.render(
  <Router>
      <App />
  </Router>,
  document.getElementById('root'),
)
